<script lang="ts">
    import "../app.css"
</script>

<svelte:head>
    <script>
        // Having this in `head` will make theme application not flicker on load
        // This script is a copy from `style.ts`
        // with the only change, that `lightDarkMode` is gotten from localStorage directly
        // and that the values are in additional quotes (because of json serialization)

        // @feat: darkmode
        if (
            localStorage.lightDarkMode === undefined ||
            localStorage.lightDarkMode === null ||
            localStorage.lightDarkMode === '"system"'
        ) {
            console.info("[style] system")
            if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
                console.info("[style] dark")
                document.documentElement.classList.add("dark")
            } else {
                console.info("[style] light")
                document.documentElement.classList.remove("dark")
            }
        } else if (localStorage.lightDarkMode === '"light"') {
            console.info("[style] light")
            document.documentElement.classList.remove("dark")
        } else if (localStorage.lightDarkMode === '"dark"') {
            console.info("[style] dark")
            document.documentElement.classList.add("dark")
        }
    </script>
</svelte:head>

<!-- Stop opening file in the browser when dropped without a drop zone -->
<svelte:window on:drop|preventDefault={() => {}} on:dragover|preventDefault />

<!-- Routes -->
<slot />
